import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store/modules/store'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        store
    }
})