<template>
    <v-app>
        <v-main style="height: 100vh;max-height: 100vh;display: flex;flex-direction: column;background-color: #F7F2E6">
            <div style="flex: 0 0 40px;height: 40px;width: 100%;font-size: 20px;box-shadow: 0 0 3px #0004;background: rgb(230 149 0);color: #ffffff;"
                 class="d-flex align-center justify-center">
                <img src="@/assets/logo-3.png" style="height: 40px">
                <div style="margin-right: 20px" class="ml-4">Терминал Стюард.Кафе</div>
                <v-btn class="ml-4" x-small @click="leave">Выйти</v-btn>
            </div>
            <div style="max-height: calc(100vh - 41px)">
                <slot/>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "CafeLayout",
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        leave() {
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            this.$router.push('/')
        }
    },
    mounted() {
        this.$store.dispatch('getMe');
    }
};
</script>