<template>
    <div style="padding-top:50px">
        <v-form v-model="valid" v-if="waitFeedback"  style="margin-bottom: 50px">
            <v-container>
                <v-card-title>Написать разработчику</v-card-title>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field outlined v-model="name" :rules="nameRules" :counter="40" label="Ваше имя" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field outlined v-model="contact" :rules="contactRules" :counter="70" label="Ваша Почта / Telegram / Discord" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-textarea outlined label="Обращение" v-model="text">

                        </v-textarea>
                    </v-col>
                </v-row>
                <v-checkbox @click.stop="" v-model="checkbox" :rules="checkboxRules" required>
                    <template slot='label'><span>Я соглашаюсь с <router-link to="/offer">пользовательским соглашением</router-link> и <router-link to="/privacy">политикой конфиденциальности</router-link></span>
                    </template>
                </v-checkbox>
                <v-btn color="primary" class="mr-4" @click="submit" :disabled="!valid">Отправить</v-btn>
            </v-container>
        </v-form>
        <v-container v-else>
            Ваше сообщение отправлено
        </v-container>
    </div>
</template>

<script>
export default {
    name: "WriteToDev",
    data: () => ({
        waitFeedback: true,
        valid: false,
        name: '',
        nameRules: [
            v => !!v || 'Обязательное поле',
            v => v.length <= 50 || 'Имя не должно быть длиннее 50 символов',
        ],
        contact: '',
        contactRules: [
            v => !!v || 'Обязательное поле',
            v => v.length <= 70 || 'Ваш контант не должен быть длиннее 70 символов',
        ],
        text: '',
        checkbox: false,
        checkboxRules: [
            v => !!v || 'Обязательное поле'
        ]
    }),

    methods: {
        submit() {
            this.$store.dispatch('sendFeedback', {
                    target: 'write-to-dev',
                    name: this.name,
                    contact: this.contact,
                    text: this.text
            }).then(e => {
                const error = e.error;
                if(!error) {
                    this.waitFeedback = false;
                }
            });
        },
    }
}
</script>

<style scoped>

</style>