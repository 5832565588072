<template>
    <div style="padding-top: 50px">
        Страница не найдена<br>
        <router-link to="/">Вернуться на главную</router-link>
    </div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>

</style>