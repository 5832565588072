function __unten(e) {
    return e < 10 ? '0' + e : e;
}

export function formatTime(value, tpl = 'dd.MM.yyyy hh:mm', isUTC = true) {
    if (!value)
        return '';
    const date = typeof value === 'string' ? new Date(value) : value;
    const utc = isUTC ? 'UTC' : '';
    const y = date['get' + utc + 'FullYear']();
    const M = date['get' + utc + 'Month']() + 1;
    const d = date['get' + utc + 'Date']();
    const h = date['get' + utc + 'Hours']();
    const m = date['get' + utc + 'Minutes']();
    const s = date['get' + utc + 'Seconds']();
    const S = date['get' + utc + 'Milliseconds']();

    tpl = tpl.replace('MM', __unten(M))
        .replace('M', M)
        .replace('yyyy', y)
        .replace('yy', y % 100)
        .replace('dd', __unten(d))
        .replace('d', d)
        .replace('hh', __unten(h))
        .replace('h', h)
        .replace('mm', __unten(m))
        .replace('m', m)
        .replace('ss', __unten(s))
        .replace('s', s);

    return tpl;
}

export default {
    formatTime,
}