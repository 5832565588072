<template>
    <div class="holder" v-if="notification">
        <div class="main">
            <div>
            <p>
                Для улучшения работы сайта и его взаимодействия с пользователями Steward.Cafe использует файлы cookie.
                Продолжая работу с сайтом, Вы разрешаете использование cookie-файлов.
                Вы всегда можете отключить файлы cookie в настройках Вашего браузера.
            </p>
            </div>
            <div><v-btn @click="apply" color="#1976d2" style="color: #ffffff">Понятно</v-btn></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CookieNote",
    data: () => ({
        notification: false
    }),
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
        apply() {
            document.cookie = 'cookieWasNotified=ok';
            this.notification = false;
        }
    },
    mounted() {
        if(!navigator.cookieEnabled)
            return;
        const was = this.getCookie('cookieWasNotified');
        this.notification = was !== 'ok';
    }
}
</script>

<style scoped>
.holder {display: block;position: fixed;bottom:0;width: 100%;padding-bottom:20px;box-shadow: 0 0 10px #888;background: #ffffff;box-sizing: border-box;}
.main{display: flex;max-width: 900px;min-height:70px;margin:auto;align-items: center;}
.main div {flex: 1 1 auto;}
.main div p {padding: 40px;font-family: Roboto, sans-serif;}
@media (max-width: 768px) {.main{flex-direction:column;}}
</style>