import MetricService from '../../services/metric.service'
import FeedbackService from '../../services/feedback.service'
import api from '../../utils/api';

export default {
    state: {
        metric: null,
        user: null,
    },

    mutations: {
        SET_METRIC(state, data) {
            state.metric = data;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_TABLES(state, tables) {
            state.tables = tables;
        }
    },

    actions: {
        getMetric({ commit }) {
            return new Promise((resolve) => MetricService.getMetric().then(e => {
                commit('SET_METRIC', e);
                resolve(e);
            }));
        },
        sendFeedback(_, data) {
            return FeedbackService.sendFeedback(data.target, data.name, data.contact, data.text);
        },

        login({ commit }, data) {
            return new Promise(resolve => {
                api.post('login', { email: data.email, password: data.password }).then(e => {
                    if (e.status === 200) {
                        commit('SET_USER', e.json.user);
                        localStorage.setItem('access', e.json.access);
                        localStorage.setItem('refresh', e.json.refresh);
                    }
                    resolve(e);
                });
            })
        },
        getMe({ commit, state }) {
            if (state.user)
                return;
            api.authPost('getUserInfo').then(e => {
                if (e.status === 200)
                    commit('SET_USER', e.json.user);
            })
        },

        getTables: () => api.authPost('getTables'),
        addTable: (_, data) => api.authPost('addTable', data),
        updateTable: (_, data) => api.authPost('updateTable', data),
        removeTable: (_, data) => api.authPost('removeTable', data),

        getActions: (_, data) => api.authPost('getActions', data),

        getActiveWorkplace: () => api.authPost('getActiveWorkplace'),
        updateWorkplace: (_, data) => api.authPost('updateWorkplace', data),

        updateUser: ({ commit }, data) => {
            return new Promise(resolve => {
                api.authPost('updateUser', data).then(e => {
                    if (e.status >= 300)
                        return resolve(e);
                    commit('SET_USER', e.json.user);
                    resolve(e);
                })
            });
        },
    },

    getters: {
        GET_METRIC(state) {
            return state.metric;
        },
        user(state) {
            return state.user;
        }
    }
}

