import config from "@/config";


class MetricService {
    getMetric() {
        return fetch(config.URL + '/metric', {method: 'GET'}).then(e => e.json());
    }
}

export default new MetricService();
