<template>
    <v-app>
        <Navbar/>
        <v-main>
            <v-container style="max-width: 960px">
                <slot/>
            </v-container>
        </v-main>
        <Footer/>
    </v-app>
</template>

<script>
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default {
    name: "DefaultLayout",
    components: {
        Navbar,
        Footer

    }
};
</script>

<style scoped>

</style>