<template>
    <div style="padding-top:50px">

        <h1 class="top-h1">Гостям кафе</h1>
        <div class="over-instruction">
            <p>
                Нет большего дискомфорта для завсегдатаев кафе, ресторанов, баров, чем дискомфорт,
                возникающий когда не можешь подозвать официанта мгновенно, если возникла потребность.
                А ведь официант так нужен сейчас и немедленно, ведь гастрономические желания требуют
                скорейшего удовлетворения. Однако, официант занят кем-то другим и не смотрит в вашу
                сторону и бежит на кухню исполнять чьё-то другое из-за соседнего столика желание.
            </p>

            <p>
                Теперь помочь вам в этом непростом деле привлечения внимания официанта легко.
                Теперь есть мы и наша платформа коротких уведомлений для официантов steward.cafe.
            </p>

            <p>
                Когда вы сели за столик в летнем кафе и к вам подошёл официант, держа увесистое меню
                в руках, спросите его (а может это будет она - официантка) использует ли он платформу
                Стюард.Кафе. Если он отрицательно покачает головой в ответ, то настоятельно прекомендуйте
                ему освоить нехитрый функционал платформы прямо сейчас. А если официант кивнет вам в ответ,
                то дальнейшие шаги ему хорошо известны.
            </p>
        </div>

        <div class="instruction">
            <h1 class="sz-22">
                Как получить доступ и как пользоваться гостю кафе
            </h1>
            <div class="row">
                <div class="num">1</div>
                <div class="text">
                    <p>Уважаемый Гость ресторана, получить доступ к платформе коротких уведомлений для официантов
                        Steward.Cafe вы можете в момент получения меню от официанта, когда вы только сели за столик.</p>
                    <p>Сообщите официанту, что вы будете приглашать его через Steward.Cafe.
                    Если он ещё не подключил свой смартфон к платформе, то настоятельно порекомендуйте ему это сделать сейчас, при вас.
                        Ведь и для Гостя ресторана и для Официанта Стюард.Кафе работает бесплатно круглосуточно навсегда.</p>
                    <p>Как только вы считали QR-код с экрана смартфона официанта, вы увидите свой экран как на картинке справа.
                        Для наглядности посмотрите <router-link to="/promo">промо ролик</router-link>.</p>
                </div>
                <div class="photo">
                    <img src="/client_pic_1.jpg">
                </div>
            </div>
            <div class="zigzag"></div>
            <div class="row">
                <div class="num">2</div>
                <div class="text">
                    <p>Перед вами четыре яркие кнопки , каждая из которых имеет своё
                    уникальное название. При нажатии на любую из них на смартфон Официанта
                    отправится ваше горячее желание, которое соответствует смыслу названия кнопки,
                        и которое Официант немедленно исполнит (в меру своих физических возможностей).</p>
                    <p>Возможно в кафе вы пришли не одни, а в компании друзей. И в этом случае, если
                    вы были первым, кто подключился к Официанту, то поделитесь QR-кодом,
                        нажав на соответствующий символ в верхнем левом углу экрана вашего телефона.</p>
                    <p>А если в компании с вами за одним столом сидят иностранцы, то вы не только
                    поделитесь с ними QR-кодом, но и покажите кнопку смены языков в верхнем правом углу
                        экрана. Как всегда, основной язык - русский.</p>
                </div>
                <div class="photo">
                    <img src="/client_pic_1.jpg">
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: "Clients"
}
</script>

<style scoped>
</style>