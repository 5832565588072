<template>
    <div style="padding-top:50px">
        <h1>Пользовательское Соглашение</h1>

        <p>
            Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем steward.cafe
            (далее Стюард.Кафе - официант в твоем телефоне или Администрация) с одной стороны и пользователем сайта с другой.<br>
            Сайт Стюард.Кафе - официант в твоем телефоне не является средством массовой информации.<br><br>
            Используя сайт, Вы соглашаетесь с условиями данного соглашения.<br>
            <strong>Если Вы не согласны с условиями данного соглашения, не используйте сайт Стюард.Кафе - официант в твоем телефоне!</strong>
        </p>

        <h2>Права и обязанности сторон</h2>

        <p><strong>Пользователь имеет право:</strong><br>
        - осуществлять поиск информации на сайте<br>
        - получать информацию на сайте<br>
        - копировать информацию на другие сайты с указанием источника<br>
        - копировать информацию на другие сайты с разрешения Администрации сайта<br>
        - копировать информацию на другие сайты с разрешения правообладателя<br>
        - использовать информацию сайта в личных некоммерческих целях<br>
        - использовать информацию сайта в коммерческих целях без специального разрешения</p>

        <p><strong>Администрация имеет право:</strong><br>
            - по своему усмотрению и необходимости создавать, изменять, отменять правила<br>
            - ограничивать доступ к любой информации на сайте<br>
            - создавать, изменять, удалять информацию<br>
            - удалять учетные записи<br>
            - отказывать в регистрации без объяснения причин
        </p>

        <p><strong>Пользователь обязуется:</strong><br>
            - обеспечить достоверность предоставляемой информации<br>
            - обеспечивать сохранность личных данных от доступа третьих лиц<br>
            - обновлять Персональные данные, предоставленные при регистрации, в случае их изменения<br>
            - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или
            религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность<br>
            - не нарушать работоспособность сайта<br>
            - не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу<br>
            - не совершать действия, направленные на введение других Пользователей в заблуждение<br>
            - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам<br>
            - не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ<br>
            - не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию,
            размещение которой запрещено или противоречит нормам действующего законодательства РФ<br>
            - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами<br>
        </p>

        <p><strong>Администрация обязуется:</strong><br>
            - поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.
        </p>

        <h2>Ответственность сторон</h2>
        <p>
            - пользователь лично несет полную ответственность за распространяемую им информацию<br>
            - администрация не несет никакой ответственности за достоверность информации, скопированной из других источников<br>
            - администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг<br>
            - администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами<br>
            - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, <br>
            стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации,
            размещённой Пользователем, а также бесперебойную работу информационного ресурса
        </p>

        <h2>Условия действия Соглашения</h2>
        <p>
            Данное Соглашение вступает в силу при любом использовании данного сайта.<br>
            Соглашение перестает действовать при появлении его новой версии.<br>
            Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.<br>
            Администрация не оповещает пользователей об изменении в Соглашении.<br>
        </p>

        <p>Обновлено: 30 Мая 2022 года</p>
        <p>г. Москва, Автор и разработчик RigelLab.ru</p>
    </div>
</template>

<script>
export default {
    name: "Offer"
}
</script>

<style scoped>
h1 {color: #006dad;font-size: 2.2em;text-align: center;margin-bottom: 24px;}
h2 {color: #444;font-size: 1.8em;text-align: center;margin-bottom: 16px;}
h3 {color: #222;font-size: 1.6em;text-align: center}
p {color: #333;padding: 0 0 0.5em;font: 1em Arial;line-height: 150%}
</style>