import Vue from 'vue'
import Router from 'vue-router'
import Advertisers from "./views/Advertisers";
import Offer from "./views/Offer";
import Privacy from "./views/Privacy";
import Clients from "./views/Clients";
import Waiters from "./views/Waiters";
import Promo from "./views/Promo";
import WriteToDev from "./views/WriteToDev";
import ErrorOnSite from "./views/ErrorOnSite";
import PageNotFound from "./views/PageNotFound";
import Home from "./views/Home";
import ComOffer from "./views/ComOffer";
import Workplace from "./views/Workplace.vue";
import Restaurants from "./views/Restaurants.vue";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/promo',
            name: 'promo',
            component: Promo,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/restaurants',
            name: 'restaurants',
            component: Restaurants,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/write-to-dev',
            name: 'write-to-dev',
            component: WriteToDev,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/error-or-site',
            name: 'error-or-site',
            component: ErrorOnSite,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/advertisers',
            name: 'advertisers',
            component: Advertisers,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/clients',
            name: 'clients',
            component: Clients,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/waiters',
            name: 'waiters',
            component: Waiters,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/offer',
            name: 'offer',
            component: Offer,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy,
            meta: {
                layout: 'default-layout',
            }
        },
        {
            path: '/com-offer',
            name: 'com-offer',
            component: ComOffer,
            meta: {
                layout: 'default-layout',
            }
        },

        {
            path: '/workplace',
            name: 'workplace',
            component: Workplace,
            meta: {
                layout: 'cafe-layout',
            }
        },

        {path: '/client'},
        {path: '/journal'},

        {
            path: '*',
            name: '404',
            component: PageNotFound,
            meta: {
                layout: 'default-layout'
            }
        }
    ],
    scrollBehavior () {
        return { y: 0 }
    },
});


// router.beforeEach(async (to, from, next) => {
//     next()
// })

export default router;
