import config from "@/config";


class FeedbackService {
    sendFeedback(target, name, contact, text) {
        return fetch(config.URL + 'feedback', {
            method: 'POST',
            headers: {'Content-Type': 'application/json;charset=utf-8'},
            body: JSON.stringify({target: target, name: name, contact: contact, text: text})
        }).then(e => e.json());
    }
}

export default new FeedbackService();