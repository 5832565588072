<template>
    <div>
        <v-app-bar app color="#f2f2f2">
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <router-link to="/" class="logo-image"><img src="@/assets/logo-3.png"></router-link>
            <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" @click="showWaiterQr" icon><v-icon>mdi-share-variant</v-icon></v-btn>
                </template>

                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
                        <v-card-title style="word-break: break-word; font-size: 16px; line-height: 20px;font-weight: 700">Познакомь официанта со Стюард.Кафе</v-card-title>
                    </v-toolbar>

                    <v-card-text>
                        <img src="/waiter-qr.png" class="qr-waiter">
                        <p class="qr-waiter-text">Покажите это QR-код официанту, если он ещё не подключён к сервису вызова официантов Steward.Cafe.</p>
                        <p class="qr-waiter-text">Ссылка ведёт на страницу с инструкцией для официанта.</p>
                        <p class="qr-waiter-text">Чтобы вызвать этот QR-код, нажмите на кнопку <v-icon color="primary">mdi-share-variant</v-icon> сверху экрана.</p>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="closeDialog">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <div style="width: 100px" class="hidden-sm-and-down"></div>
            <v-spacer class="hidden-md-and-up"></v-spacer>

            <v-toolbar-items class="hidden-sm-and-down">
                <v-btn v-for="item in getMenu" :key="item.link" :to="item.link" text>{{ item.name }}</v-btn>
                <v-btn @click="goToJournal" text>{{ journal.name }}</v-btn>
            </v-toolbar-items>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="hidden-md-and-up">
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="item in getMenu" :key="item.link" link :to="item.link">
                        <v-list-item-title v-text="item.name"/>
                    </v-list-item>
                    <v-list-item list @click="goToJournal">
                        <v-list-item-title v-text="journal.name"/>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-spacer class="hidden-sm-and-down"></v-spacer>
        </v-app-bar>
    </div>
</template>

<script>

export default {
    name: "Navbar",
    components: {},
    data: () => ({
        dialog: false,
        journal: {
            link: 'https://steward.cafe/journal',
            name: 'Журнал'
        }
    }),
    computed: {
        getMenu() {
            return [
                {
                    link: '/waiters',
                    name: 'Официантам'
                },
                {
                    link: '/clients',
                    name: 'Гостям кафе'
                },
                {
                    link: '/restaurants',
                    name: 'Ресторанам и кафе'
                },
                {
                    link: '/advertisers',
                    name: 'Рекламодателям'
                },
                {
                    link: '/promo',
                    name: 'Промо'
                }
            ];
        }
    },
    methods: {
        showWaiterQr() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        goToJournal() {
            window.location.href = this.journal.link
        }
    },
    mounted() {
        let _this = this;
        this.$router.beforeEach(async(to, from, next) => {
            window.scrollTo(0, 0);
            if(from.path !== to.path && to.path === '/clients') {
                _this.showWaiterQr();
            }
            next();
        });
    }
};
</script>

<style scoped>
.qr-waiter{display: block;margin: 50px auto 20px auto;}
.qr-waiter-text{font-size: 18px;}
.logo-image {height: 100%;}

.logo-image img {display: block;height: 100%;}
</style>