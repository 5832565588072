<template>
    <div class="d-flex w-100" style="max-height: calc(100vh - 41px);box-sizing: border-box;padding: 0 16px 0 16px">
        <div style="flex: 1 1 40%;max-height: 100%;overflow-y:auto;">
            <div style="height: 16px"></div>
            События
            <div class="overflow-auto">
                <div v-for="e in actions" :key="+e.datetime" :class="['action-card', e.new && 'new-action-card']" @click="e.new = false">
                    <div style="flex: 0 0 70px">
                        <div>{{ formatTime(e.datetime, 'hh:mm', false) }}</div>
                        <div style="font-size: 10px">{{ formatTime(e.datetime, 'dd.MM.yyyy', false) }}</div>
                    </div>
                    <div style="font-weight: 700;font-size: 20px;flex: 0 0 100px">
                        {{ e.name }}
                    </div>
                    <div>
                        {{ e.action }}
                    </div>
                </div>
            </div>
            <div style="height: 16px"></div>
        </div>
        <div style="flex: 1 1 20%;margin-left: 20px;max-height: 100%;overflow-y:auto;">
            <div style="height: 16px"></div>
            Столы
            <div class="overflow-auto">
                <div v-for="e in tables" :key="e.hash" class="mb-2" style="background: #FFFFFF; border-radius: 4px">
                    <v-card-title class="pa-1 d-flex justify-space-between">
                        <div>
                            № {{ e.name }}
                        </div>
                        <div>
                            <v-icon small color="green" class="mr-1" v-if="e.flags & 1">mdi-printer-outline</v-icon>

                            <v-btn icon small :href="`https://steward.cafe/client/${e.hash}`" target="_blank">
                                <v-icon small>mdi-link</v-icon>
                            </v-btn>

                            <v-btn icon small @click="openTable(e)">
                                <v-icon small>mdi-pencil-outline</v-icon>
                            </v-btn>
                        </div>
                    </v-card-title>
                </div>
            </div>
            <div class="d-flex justify-space-between mt-2">
                <v-btn color="primary" @click="openTable()" outlined>Добавить стол</v-btn>
                <v-btn color="primary" icon @click="print.dialog = true">
                    <v-icon>mdi-printer-outline</v-icon>
                </v-btn>
            </div>
            <div style="height: 16px"></div>
        </div>
        <div style="flex: 1 1 40%;margin-left: 20px;max-height: 100%;overflow-y:auto;">
            <div style="height: 16px"></div>
            Заведение

            <v-text-field v-model="workplace.name" outlined dense label="Название" :error-messages="workplaceError.name" class="mt-2"/>
            <v-text-field v-model="workplace.address" outlined dense label="Адрес" :error-messages="workplaceError.address"/>
            <v-text-field v-model="workplace.pdfMenu" outlined dense label="Ссылка на меню" clearable/>
            <v-text-field v-model="workplace.tips" outlined dense label="Ссылка на чаевые (общие)" clearable/>
            <div class="d-flex">
                <v-text-field v-model="workplace.wifiName" outlined dense label="Название WiFi сети кафе (если есть)" clearable hide-details/>
                <v-text-field v-model="workplace.wifiPassword" outlined dense class="ml-2" label="Пароль от WiFi (если есть)" clearable hide-details/>
            </div>
            <v-checkbox v-model="workplace.hookah" label="В заведении есть кальян"/>
            <v-btn class="mt-2" @click="saveWorkplace" color="primary" :loading="workplaceLoading" outlined>Сохранить заведение</v-btn>

            <div class="mt-5" v-if="user">
                Официант
                <v-text-field :value="user.name" outlined dense label="Имя официанта" class="mt-2" @change="waiterName = $event" hide-details/>
                <v-text-field :value="user.email" outlined dense label="Почта" class="mt-4" readonly hide-details/>
                <v-text-field :value="user.telegramUsername" outlined dense label="Telegram" class="mt-4" readonly hide-details/>
                <v-btn class="mt-4" @click="updateWaiter" color="primary" :loading="waiterLoading" outlined>Сохранить официанта</v-btn>
            </div>

            <div class="mt-8 d-flex flex-column align-end">
                <v-btn color="primaru" outlined to="/write-to-dev" target="_blank" small>Отправить идею разработчику</v-btn>
                <div class="mt-1">
                    Steward.Cafe &copy; разработчик <a href="https://rigellab.ru" target="_blank">RigelLab</a> {{ (new Date()).getFullYear() }}
                </div>
                <div>
                    <a href="https://steward.cafe" target="_blank"> Сайт проекта Стюард.Кафе</a>
                </div>
            </div>

            <div style="height: 16px"></div>
        </div>

        <v-dialog v-model="table.dialog" width="700">
            <v-card>
                <v-card-title>{{ table.selected ? 'Изменение стола' : 'Добавление стола' }}</v-card-title>
                <v-card-text class="mt-2">
                    <v-text-field label="Номер стола" v-model="table.name" outlined dense counter="10"/>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn text @click="table.dialog = false">Отмена</v-btn>
                    <v-btn color="primary" @click="saveTable">{{ table.selected ? 'Сохранить' : 'Добавить' }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="print.dialog" width="700" scrollable>
            <v-card>
                <v-card-title>Печать QR кодов столов</v-card-title>
                <v-card-text>
                    <v-checkbox v-for="e in tables" :key="e.hash" :label="e.name" v-model="print.selected" :value="e.hash" hide-details/>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn text @click="print.dialog = false">Отмена</v-btn>
                    <v-btn color="primary" @click="printTables">Печать</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import collections, {toArray} from "../utils/collections";
import config from "@/config";
import {formatTime} from "../utils/format";
import {mapGetters} from "vuex";


let clientWs = null;
export default {
    data: () => ({
        tables: [],
        events: [],
        table: {
            qrDialog: false,
            selected: null,
            name: '',
            updateQr: false,
            dialog: false,
            deleteDialog: false,
            loading: false,
        },
        actionNames: [
            '',
            'Подойдите',
            'Уберите стол',
            'Принесите меню',
            'Принесите счёт',
            'Принесите счёт наличными',
            'Принесите счёт картой',
            'Позовите мастера кальянов'
        ],
        actions: [],

        waiterName: null,
        waiterLoading: false,

        workplaceLoading: false,
        workplaceError: {
            name: null,
            address: null,
        },
        workplace: {
            id: 0,
            name: '',
            address: '',
            pdfMenu: '',
            hookah: false,
            tips: '',
            wifiName: '',
            wifiPassword: '',
            wifiAuth: '',
        },

        print: {
            dialog: false,
            selected: [],
            sendToEmail: false,
        }
    }),
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        formatTime,
        openTable(selected) {
            this.table.selected = selected || null;
            this.table.updateQr = false;
            this.table.name = selected?.name || '';
            this.table.dialog = true;
        },
        sortTables() {
            this.tables.sort((a, b) => (+a.name.replace(/\D/g, '') || a.name) - (+b.name.replace(/\D/g, '') || b.name));
        },
        saveTable() {
            this.table.loading = true;
            if (this.table.selected) {
                const hash = this.table.selected.hash;
                this.$store.dispatch('updateTable', { hash, name: this.table.name, qr: this.table.updateQr }).then(e => {
                    this.table.loading = false;
                    if (e.status >= 300)
                        return;
                    collections.remove(this.tables, hash, 'hash');
                    collections.addOrReplace(this.tables, e.json.table, 'hash');
                    this.sortTables();
                    this.table.dialog = false;
                });
            }
            else {
                this.$store.dispatch('addTable', { name: this.table.name, pdf: true }).then(e => {
                    this.table.loading = false;
                    if (e.status >= 300)
                        return;
                    collections.addOrReplace(this.tables, e.json.table, 'hash');
                    this.sortTables();
                    this.table.dialog = false
                });
            }
        },
        removeTable() {

        },
        restoreTable() {

        },
        printTables() {
            window.open(`${config.URL}generatePdfTables?token=${localStorage.getItem('access')}&download=true&tables=${this.print.selected.join(' ')}`);
        },

        saveWorkplace() {
            this.workplaceError.name = null;
            this.workplaceError.address = null;
            if (!this.workplace.name)
                return this.workplaceError.name = 'Укажите название заведения';
            if (!this.workplace.address)
                return this.workplaceError.address = 'Укажите адрес заведения';

            this.workplaceLoading = true;
            this.$store.dispatch('updateWorkplace', this.workplace).then(e => {
                this.workplaceLoading = false;
                if (e.status >= 300)
                    return;
                Object.keys(this.workplace).forEach(key => this.workplace[key] = key.startsWith('wifi') ? (e.json.workplace.wifi || {})[key.substring(4).toLowerCase()] : e.json.workplace[key]);
            });
        },

        updateWaiter() {
            if (this.waiterName === null)
                return;
            this.waiterLoading = true;
            this.$store.dispatch('updateUser', { name: this.waiterName }).then(e => {
                if (e.status >= 300)
                    return;
                this.waiterLoading = false;
            })
        }
    },
    mounted() {
        document.documentElement.style = 'overflow-y: unset';

        this.$store.dispatch('getTables').then(e => {
            if (e.status !== 200)
                return;
            this.tables = e.json.tables.filter(u => u.flags & 2);
            this.sortTables();
        });
        this.$store.dispatch('getActions').then(e => {
            if (e.status !== 200)
                return;
            const now = +new Date();
            this.actions = e.json.actions.map(u => ({ new: false, name: u[0], action: this.actionNames[u[1]], datetime: new Date(now - 1000 * u[2]) })).reverse();
        });

        this.$store.dispatch('getActiveWorkplace').then(e => {
            if (e.status !== 200)
                return;
            Object.keys(this.workplace).forEach(key => this.workplace[key] = key.startsWith('wifi') ? (e.json.workplace.wifi || {})[key.substring(4).toLowerCase()] : e.json.workplace[key]);
        });


        clientWs = new WebSocket(config.SOCKET_URL, 'websocket');
        clientWs.onmessage = (e) => {
            const json = JSON.parse(e?.data || '{}');
            if (json.action === 'action')
                this.actions.unshift({ new: true, name: json.name, action: this.actionNames[json.id], datetime: new Date() });
            if (this.actions.length > 120)
                this.actions.splice(120, this.actions.length - 120);
        };
        clientWs.onopen = (e) => {
            clientWs.send(JSON.stringify({ action: 'auth', token: localStorage.getItem('access') }));
        };
    }
}
</script>


<style scoped>
.action-card {
    background-color: #ffffff;
    border-radius: 4px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px;
}

.new-action-card {
    background: #F8734A !important;
    color: #FFFFFF;
}

.new-action-card:hover {
    cursor: pointer;
}
</style>