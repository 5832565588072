<template>
    <div style="padding-top:50px">

        <h1 class="top-h1">Рекламодателям</h1>
        <div class="over-instruction">
            <p>
                "Здесь могла бы быть ваша реклама" - фраза набившая самую крепкую оскомину всякому современнику,
                а рекламодателю, пожалуй, еще и добавившая седин. Так много кроется за этой банальной короткой
                риторикой нашего отчаянно быстрого делового мира. Так же и мы не смогли пройти мимо "двигателя
                торговли", ставшего неотъемлемой частью развития любого уважающего себя startup'а.
            </p>
        </div>

        <p class="p-form-header">Если вы рекламное агентство, то воспользуйтесь формой ниже, чтобы предложить нам сотрудничество.</p>

        <v-form v-model="valid" v-if="waitFeedback" class="form">
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field outlined v-model="name" :rules="nameRules" :counter="40" label="Ваше имя / название организации" required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12">
                    <v-text-field outlined v-model="contact" :rules="contactRules" :counter="70" label="Ваша Почта / Telegram / Discord / etc." required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12">
                    <v-textarea outlined label="Коммерческое предложение" v-model="text">

                    </v-textarea>
                </v-col>
            </v-row>
            <v-checkbox @click.stop="" v-model="checkbox" :rules="checkboxRules" required>
                <template slot='label'><span>Я соглашаюсь с <router-link to="/offer">пользовательским соглашением</router-link> и <router-link to="/privacy">политикой конфиденциальности</router-link></span>
                </template>
            </v-checkbox>
            <v-btn color="primary" class="mr-4" @click="submit" :disabled="!valid">Отправить</v-btn>
        </v-form>
        <v-container v-else>
            Ваше коммерческое предложение отправлено
        </v-container>
    </div>
</template>

<script>
export default {
    name: "Advertisers",
    data: () => ({
        waitFeedback: true,
        valid: false,
        name: '',
        nameRules: [
            v => !!v || 'Обязательное поле',
            v => v.length <= 50 || 'Имя не должно быть длиннее 50 символов',
        ],
        contact: '',
        contactRules: [
            v => !!v || 'Обязательное поле',
            v => v.length <= 70 || 'Ваш контант не должен быть длиннее 70 символов',
        ],
        text: '',
        checkbox: false,
        checkboxRules: [
            v => !!v || 'Обязательное поле'
        ]
    }),

    methods: {
        submit() {
            this.$store.dispatch('sendFeedback', {
                target: 'ad',
                name: this.name,
                contact: this.contact,
                text: this.text
            }).then(e => {
                const error = e.error;
                if (!error) {
                    this.waitFeedback = false;
                }
            });
        },
    }
}
</script>

<style scoped>
a{display: inline}
.p-form-header{padding: 30px 20px 10px 20px;}
.form{margin-bottom: 50px;padding: 0 20px;}
</style>