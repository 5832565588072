<template>
    <div style="padding-top:50px">
        <h1 class="top-h1">Ресторанам и кафе</h1>
        <div class="over-instruction">
            <p>
                Уважаемые рестораторы и владельцы кафе, сервис-платформа Стюард.Кафе предлагает простой, изящный и бесплатный механизм вызова официантов
                к столикам ваших заведений. Для старта работы сервиса достаточно зарегистрировать ваше заведение в нашем сервисе, расставить QR-коды
                на столы в ваших залах и назначить ответственного Менеджера Зала для приема сообщений от Посетителей.
            </p>
        </div>
        <div class="d-flex" style="margin-top: 50px">
            <div style="flex: 1 1 50%">
                <v-card-title>Войти</v-card-title>
                <v-text-field v-model="email" outlined dense label="Почта" :error-messages="errEmail"/>
                <v-text-field v-model="password" outlined dense type="password" label="Пароль" :error-messages="errPass"/>
                <div class="d-flex justify-end">
                    <v-btn color="primary" @click="login">Войти</v-btn>
                </div>
            </div>
            <div style="flex: 1 1 50%;margin-left: 32px">
                <v-card-title>Отправить запрос на подключение</v-card-title>
                <v-form v-model="valid" v-if="waitSendRequest" style="margin-bottom: 50px">
                    <v-text-field outlined dense v-model="name" :rules="nameRules" :counter="40" label="Название вашего заведения" required/>
                    <v-text-field outlined dense v-model="contact" :rules="contactRules" :counter="70" label="Ваша почта" required/>
                    <v-text-field outlined dense v-model="phone" :rules="phoneRules" :counter="70" label="Ваш номер телефона / Telegram / Discord" required/>
                    <v-checkbox v-model="checkbox" :rules="checkboxRules" required>
                        <template slot='label'><span>Я соглашаюсь с <router-link to="/offer">пользовательским соглашением</router-link> и <router-link
                            to="/privacy">политикой конфиденциальности</router-link></span>
                        </template>
                    </v-checkbox>
                    <v-btn color="primary" class="mr-4" @click="submit" :disabled="!valid" :loading="rLoading">Отправить запрос на подключение</v-btn>
                </v-form>
                <v-div v-else>
                    Ваш запрос на подклчение отправлен
                </v-div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        email: '',
        password: '',
        errEmail: null,
        errPass: null,

        rLoading: false,
        waitSendRequest: true,
        valid: false,
        name: '',
        nameRules: [
            v => !!v || 'Обязательное поле',
            v => v.length <= 50 || 'Название заведения не должно быть длиннее 50 символов',
        ],
        contact: '',
        contactRules: [
            v => !!v || 'Обязательное поле',
            v => v.length <= 70 || 'Ваша почта не должна быть длиннее 70 символов',
        ],
        phone: '',
        phoneRules: [
            v => !!v || 'Обязательное поле',
            v => v.length <= 70 || 'Ваш телефон не должен быть длиннее 70 символов',
        ],
        text: '',
        checkbox: false,
        checkboxRules: [
            v => !!v || 'Обязательное поле'
        ]
    }),
    methods: {
        login() {
            this.errEmail = null;
            this.errPass = null;
            this.$store.dispatch('login', { email: this.email, password: this.password }).then(e => {
                if (e.status === 404)
                    this.errEmail = 'email not found';
                if (e.status === 403)
                    this.errPass = 'wrong password';
                if (e.status === 200)
                    this.$router.push('/workplace')
            });
        },
        submit() {
            this.rLoading = true;
            this.$store.dispatch('sendFeedback', {
                target: 'write-to-dev',
                name: this.name,
                contact: `Почта ${this.contact}, PTD ${this.phone}`,
                text: `Запрос на добавлние заведения`
            }).then(e => {
                const error = e.error;
                this.rLoading = false;
                if (!error) {
                    this.name = '';
                    this.contact = '';
                    this.phone = '';
                    this.waitSendRequest = false;
                }
            });
        },
    }
}
</script>

<style scoped>

</style>