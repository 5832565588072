<template>
    <div>
        <div class="holder">
            <div class="col1">
                <h1><span class="blue-bg">Сервис Вызова Официантов</span></h1>
                <div class="row">
                    <span class="num">{{ 228 }}</span>
                    <span class="num-text">{{ counted(228, times) }} <b>позвали</b> официанта</span>
                </div>

                <div class="row">
                    <span class="num">{{ 206 }}</span>
                    <span class="num-text">{{ counted(206, humans) }} всего</span>
                </div>

                <div class="row">
                    <span class="num">{{ 54 }}</span>
                    <span class="num-text">{{ counted(54, times) }} попросили <b>счёт</b></span>
                </div>

                <div class="row">
                    <span class="num">{{ 55 }}</span>
                    <span class="num-text">{{ counted(55, times) }} попросили <b>меню</b></span>
                </div>

                <div class="row" style="margin-bottom: 50px">
                    <span class="num">{{ 60 }}</span>
                    <span class="num-text">{{ counted(60, waiters) }} в сервисе</span>
                </div>
            </div>
            <div class="col2">
                <h1 style="text-align: left;margin-left: 0">Стюард.Кафе</h1>
                <div class="rost-row">
                    <div></div>
                    <div></div>
                </div>
                <div class="foo">
                    <div class="rost-col">
                        <div></div>
                        <div></div>
                    </div>
                    <h2>Сервис вызова объединяет<br>
                        <div class="pad"></div>
                        Официантов<br>
                        <div class="pad"></div>
                        <div class="pad"></div>
                        и Гостей
                        <br> ресторанов, кафе, баров<br>
                        <div class="pad"></div>
                        на единой<br>
                        <div style="text-align: center;"><span style="background: #CEFBD0;padding: 5px;">бесплатной</span></div>
                        <div style="text-align: right">платформе</div>
                    </h2>
                    <div class="rost-col">
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div class="rost-row">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

        <div class="d-flex align-center flex-column instruction" style="margin-top: 50px">
            <h1 style="align-self: center;margin-bottom: 20px">Рестораны где мы есть</h1>
            <div class="rest-exist">
                <div class="rest-banner" style="background: #f1ffee">

                </div>
                <div class="rest-info">
                    <div class="unit">
                        <img src="@/assets/site.png" class="grayscale" style="width: 32px">
                    </div>
                    <div class="unit">
                        <a href=""><img src="@/assets/vk.png" class="grayscale" style="width: 32px"></a>
                    </div>
                    <div class="unit">
                        <a href=""><img src="@/assets/telegram.png" class="grayscale" style="width: 32px"></a>
                    </div>
                    <div class="unit">
                        <a href=""><img src="@/assets/yandex-map.png" class="grayscale" style="width: 32px"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Home",
    data: () => ({
        times: ['раз', 'раза', 'раз'],
        waiters: ['официант участвует', 'официанта участвуют', 'официантов участвуют'],
        humans: ['гость обслужен', 'гостя обслужено', 'гостей обслужено']
    }),
    computed: {
        ...mapGetters(['GET_METRIC']),
    },

    methods: {
        counted(count, names) {
            const p100 = count % 100;
            const p100_10 = p100 % 10;
            if (p100_10 === 0 || p100_10 >= 5 || 10 <= p100 && p100 <= 19)
                return names[2];
            if (p100_10 >= 2 && p100_10 <= 4)
                return names[1];
            return names[0];
        }
    },

    mounted() {
        console.log('mount home');
        this.$store.dispatch('getMetric');
    }
}
</script>

<style scoped>
.blue-bg {
    padding-left: 5px;
    padding-right: 4px;
    display: inline-block;
    background: #D6EAFF;
}

h1 {
    font-family: Roboto, sans-serif;
    display: inline-block;
    text-align: left;
    margin: 50px 20px 0 20px;
    font-weight: 400;
    letter-spacing: 2px;
}

h2 {
    font-family: Roboto, sans-serif;
    display: block;
    width: 100%;
    text-align: left;
    margin: 10px 20px 0 20px;
    font-weight: 400;
    letter-spacing: 2px;
    padding-left: 5px;
    padding-right: 4px;
}

b {
    font-weight: 700
}

.holder {
    display: flex
}

.holder .col1 {
    flex: 1 1 auto;
}

.holder .col2 {
    flex: 1 1 auto;
    max-width: 35%;
}

.holder .col2 h2 {
    margin: 0;
    padding: 20px 25px
}

.holder .col2 h1 {
    margin-bottom: 20px;
}

.holder .col2 h2 .pad {
    width: 20px;
    display: inline-block
}

.holder .col2 .rost-row {
    display: flex;
    height: 5px;
    justify-content: space-between
}

.holder .col2 .foo .rost-col {
    display: flex;
    width: 5px;
    justify-content: space-between;
    flex-direction: column
}

.holder .col2 .foo {
    display: flex;
}

.holder .col2 .rost-row div, .holder .col2 .rost-col div {
    flex: 0 0 45%;
    background: #ffc8c8;
}

.row {
    display: block;
    margin: 10px 20px;
    color: #444
}

.num-text {
    font-size: 20px;
}

.num {
    font-size: 3em;
    padding-right: 20px;
}

.instruction {
    padding: 40px;
    box-sizing: border-box;
    border: 2px dotted #444;
    border-radius: 16px;
    margin: 50px 0 100px 0;
}

.rest-exist {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
}

.rest-exist .rest-banner {
    flex: 1 1 auto;
    height: 240px;
    min-width: 300px;
}

.rest-exist .rest-info {
    align-self: flex-start;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 0 0 100px;
    margin-left: 20px;
}

.rest-exist .rest-info .unit {
    flex: 0 0 40px;
    height: 40px;
    padding: 4px 0 0 4px;
}

.rest-exist .rest-info .unit .grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media (min-width: 769px) {
    .row {
        margin-left: 50px;
    }

    h1, h2 {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media (max-width: 960px) {
    .holder {
        flex-direction: column;
    }

    .holder .col1 {
        flex-basis: 100%;
    }

    .holder .col2 {
        flex-basis: 100%;
        max-width: 100%;
        align-self: center;
        padding-bottom: 50px;
    }


}

@media (max-width: 768px) {
    .instruction {
        border: 0;
        padding: 0;
    }

    .instruction h1 {
        margin-left: 0;
        margin-right: 0;
        font-size: 28px;
    }

    .rest-exist {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .rest-exist .rest-banner {
        width: 100vw;
        flex: 0 0 160px;
        height: 160px;
    }

    .rest-exist .rest-info {
        align-self: center;
        margin-top: 16px;
        flex-wrap: nowrap;
    }
}
</style>