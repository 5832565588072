import config from "@/config";

function _internal(method, path, data, jwt, isMultipart = false) {
    const headers = isMultipart ? {} : (method === 'GET' ? {} : { 'Content-Type': 'application/json;charset=utf-8' });
    if (jwt)
        headers.Authorization = jwt;

    const body = isMultipart ? data : data ? JSON.stringify(data) : undefined;
    const mode = 'cors';

    return new Promise(resolve => fetch(config.URL + path, { method, mode, headers, body }).then(async e => {
        if (e.status < 500) {
            let json;
            try {
                json = await e.json();
            } catch (e) {
                json = null;
            }
            resolve({ json, status: e.status, response: e })
        }
        resolve({ json: {}, status: e.status })
    }).catch(e => console.log(e)));
}

const _execArrayQueue = [];
let refreshGetting = false;

function _execAuth(method, url, data, isMultipart = false) {
    return new Promise(resolve => {
        _internal(method, url, data, localStorage.getItem('access'), isMultipart).then(try1 => {
            if (try1.status === 401) {

                if (refreshGetting) {
                    _execArrayQueue.push({ method, url, data, isMultipart, resolve });
                }
                else {
                    refreshGetting = true;
                    _internal('POST', '/updateTokens', null, localStorage.getItem('refresh')).then(tokens => {
                        if (tokens.status !== 200) {
                            localStorage.removeItem('access')
                            localStorage.removeItem('refresh')
                            window.location.href = '/login';
                            resolve({ status: 401, json: {} });
                            return;
                        }

                        const access = tokens.json.access;
                        localStorage.setItem('access', access);
                        localStorage.setItem('refresh', tokens.json.refresh);

                        _internal(method, url, data, access, isMultipart).then(e => resolve(e));
                        while (_execArrayQueue.length) {
                            const ask = _execArrayQueue.shift();
                            _internal(ask.method, ask.url, ask.data, access, ask.isMultipart).then(e => ask.resolve(e));
                        }
                        refreshGetting = false;
                    });
                }
            }
            else
                resolve(try1);
        });
    });
}

export function authGet(url, data) {
    if (data) {
        const arr = [];
        for (const [key, value] of Object.entries(data)) {
            if (value !== undefined)
                arr.push(`${key}=${value}`);
        }
        if (arr.length)
            url += (url.indexOf('?') > 0 ? '&' : '?') + arr.join('&');
    }

    return _execAuth('GET', url);
}

export function authPost(url, data) {
    return _execAuth('POST', url, data);
}

export function authPut(url, data) {
    return _execAuth('PUT', url, data);
}

export function authPatch(url, data) {
    return _execAuth('PATCH', url, data);
}

export function authDelete(url, data) {
    return _execAuth('DELETE', url, data);
}

export function post(url, data) {
    return _internal('POST', url, data, null);
}

export function get(url) {
    return _internal('GET', url, null, null);
}

export function authPostFile(url, file, data) {
    let formData = new FormData();
    formData.append('file', file);
    for (const e in data)
        e !== undefined && formData.append(e, data[e]);

    return _execAuth('POST', url, formData, true);
}

export default {
    authGet,
    authPost,
    authPostFile,
    authPut,
    authPatch,
    authDelete,
    post,
    get,
}