<template>
    <div style="padding-top: 50px">
        <div class="top-div">
            <div style="flex: 0 0 300px">
                <img src="/qr-code-com-offer.png" style="margin-bottom: 0">
                <div style="text-align: center">
                    <router-link to="/com-offer" style="font-size: 12px;">https://steward.cafe/com-offer</router-link>
                </div>
            </div>
            <div style="flex: 1 1  auto;font-size: 36px;padding-bottom: 40px;width: 300px;text-align: right" class="r">
                Коммерческое предложение<br>потенциальным партнерам<br>сервиса Steward.Cafe
            </div>
        </div>
        <p>
            Уважаемые интересанты, рады представить вам IT-сервис Steward.Cafe.
        </p>

        <p>
            <b>Кратко о функции сервиса:</b><br>
            Сервис Steward.Cafe был разработан и создан нами с целью улучшения обслуживания официантами гостей кафе и ресторанов. Главной функцией сервиса является возможность гостя пригласить/позвать официанта к
            своему столику удаленно в момент, когда гостю это крайне необходимо. Подробнее читайте на сайте сервиса по ссылке
            <router-link to="/clients">https://steward.cafe/clients</router-link>
            .
            Монетизация сервиса – продажа рекламного места/баннера рекламным агентствам.
        </p>

        <p>
            <b>Кратко о технологии сервиса:</b><br>
            Сервис Steward.Cafe имеет мобильное приложение для официантов и веб-страницу для гостей кафе, ресторанов. Гость своим смартфоном считывает qr-код с телефона официанта и
            получает доступ к веб-панели вызова официанта. Также, официанты могут использовать телеграм-бот вместо приложения. Подробнее смотрите промо-ролик на сайте сервиса по ссылке
            <router-link to="/promo">https://steward.cafe/promo</router-link>
            .
        </p>

        <p>
            <b>Дополнительные возможности сервиса:</b><br>
            Сервис Steward.Cafe имеет в своей структуре Журнал для официантов, Предложения о работе, Опросы и Новости сервиса. Эти четыре функции сервиса позволяют удерживать внимание
            официантов на сервисе и позволяют получать от официантов обратную связь.
        </p>

        <p>
            <b>Наше предложение:</b><br>
            Мы, команда разработчиков, ищем партнера для развития Сервиса Steward.Cafe на паритетных условиях. С нашей стороны мы готовы осуществлять развитие цифрового тела сервиса.
            От партнера – внедрение и популяризация сервиса в аудитории официантов. Для связи пишите на электронную почту
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <b @click="copyMail" id="mail" v-bind="attrs" v-on="on">service@steward.cafe</b>
                </template>
                <span>Нажмите чтобы скопировать</span>
            </v-tooltip>.
        </p>
    </div>
</template>

<script>
export default {
    name: "ComOffer",
    methods: {
        copyMail() {
            const a = document.getElementById('mail');
            let range = new Range();
            range.setStart(a.firstChild, 0);
            range.setEnd(a.firstChild, a.innerText.length);
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(range);
            document.execCommand('copy');
        }
    }
}
</script>

<style scoped>
#mail:hover {
    cursor: pointer;
}

.top-div {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.top-div img {
    width: 300px;
    height: 300px;
}

.top-div .r {
    align-self: end;
}

p {
    color: #333;
    padding: 0 0 16px 10px;
    font: 1em Arial;
    line-height: 150%
}

b {
    font-weight: bold;
}
</style>