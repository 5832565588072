<template>
    <div style="padding-top:50px">

        <h1 class="top-h1">Официантам</h1>
        <div class="over-instruction">
        <p>В кафе и в ресторане вы самый главный человек для гостя. Без вас гость ровным счетом ничего не может: хочет выбрать блюда для обеда - нужны вы,
            чтобы подать меню; хочет смену блюд или убрать стол - нужны вы; желает получить счет - и снова без вас гостю никуда.</p>

        <p>Вы самый желанный человек для гостя когда он заскучал, он ищет вас глазами по залу и когда не находит, то огорчается, а если видит вас и вы бросаете
            мимолетный взгляд в его сторону, то его рука взлетает вверх в страсном порыве привлечь ваше внимание - так сильно вы ему нужны.</p>

        <p>Пойдите на встречу вашему гостю, проявите ваше доброе сердце и позвольте ему сообщать свои желания через сервис вызова официантов steward.cafe.
            Пусть все желания гостя будут удолетворены оперативно быстро и вознаграждением для вас послужат его улыбка, благодушное настроение и приятные чаевые.</p>
        </div>

        <div class="instruction">
            <h1 class="sz-22">
                Как подключиться и как с этим работать официанту
            </h1>
            <div class="row">
                <div class="num">1</div>
                <div class="text">
                    <p>Чтобы подключиться к платформе steward.cafe официанту или официантке достаточно установить наше
                        <a href="https://play.google.com/store/apps/details?id=steward.cafe.steward_cafe" target="_blank"> приложение под Android</a>
                        или подключиться к <a href="https://t.me/StewardCafe_bot?start=site" target="_blank">Telegram боту</a>.</p>
                    <div class="photo" style="display: flex;">
                        <v-dialog v-model="waiterBotPicDialog" width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <img src="/bot_qr.jpg" style="max-width: 100px; margin: 0 auto; display: block;" class="lupa" v-bind="attrs" v-on="on">
                            </template>
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2" style="word-break: break-word">
                                    Поделись ботом с коллегами
                                </v-card-title>
                                <v-card-text>
                                    <img src="/bot_qr.jpg" class="image6">
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="waiterBotPicDialog = false">Закрыть</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="waiterPlayPicDialog" width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <img src="/play_market_qr.png" style="max-width: 100px; margin: 0 auto; display: block;" class="lupa" v-bind="attrs" v-on="on">
                            </template>
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2" style="word-break: break-word">
                                    Поделись ботом с коллегами
                                </v-card-title>
                                <v-card-text>
                                    <img src="/play_market_qr.png" class="image6">
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="waiterPlayPicDialog = false">Закрыть</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div><br>
<!--                    <p>При регистрации в боте мы не просим ни ваших имён, ни ваших email, ни любых других личных данных. Всё с чем мы оперируем - это ваш никнейм в Telegram.</p>-->
                    <p>При регистрации в приложении вам доступна регистрация через Telegram с переносом в приложение всех данных (места работы, столы) из Telegram.
                    Также вы можете зарегистрироваться в приложении с указанием email и имени.</p>
                    <p>Уважаемые Официанты, для вас мы работаем совершенно бесплатно. Также бесплатно мы работаем для всех гостей кафе и
                        ресторанов, и всего планетарного общепита без исключения.</p>
                </div>
                <div class="photo">
                    <img src="/waiter_pic_2_1.jpg">
                </div>
            </div>
            <div class="zigzag"></div>
            <div class="row">
                <div class="num">2</div>
                <div class="text">
                    <p>Если вы установили приложение для Android на ваш телефон, то инструкцию рекоменжуем прочитать непосредственно в приложении. Также
                    через приложение вы можете написать нам, задать любые вопросы по работе приложения.</p>
                    <p>Если вы подключились к телеграм боту для ознакомления с функциями сервиса, то вам доступны две кнопки: "Добавить стол" и "Мои столы".</p>
                    <p>"Добавить столы" - эта кнопка позволяет создать стол. Вы можете создать не более 18-ти столов одновременно. Если вам нужно создать 19-й
                        стол - удалите любоё другой из 18-ти.</p>
                    <p>"Мои столы" - эта кнопка позволяет вывести список всех созданных актуальных столов.</p>
                    <p>Обращаем ваше внимание, все кнопки находсятся в самом низу вашего экрана смартфона.
                        Если вы не видите кнопки - нажмите символ <img src="/telegram_pic_1.png" class="telegram-pic-1"></p>
                </div>
                <div class="photo">
                    <img src="/waiter_pic_2.jpg">
                </div>
            </div>
            <div class="zigzag"></div>
            <div class="row">
                <div class="num">3</div>
                <div class="text">
                    <p>Если вы как официант только что познакомились с нашим <i>сервисом вызова официантов Steward.Cafe</i>, то первой нажмите кнопку "Добавить стол".</p>
                    <p>Бот предложит вам указать номер стола.</p>
                    <p>Укажите номер стола, который вы хотите обслужить, и за которым уже сидит ваш гость.</p>
                    <p>Кроме цифры, обозначающей номер стола вы можете внести и текстовый комментарий рядом с номером.</p>
                    <p>И помните, что номер / название стола ваш гость увидит в своей части платформы Steward.Cafe.</p>
                </div>
                <div class="photo">
                    <img src="/waiter_pic_3.jpg">
                </div>
            </div>
            <div class="zigzag"></div>
            <div class="row">
                <div class="num">4</div>
                <div class="text">
                    <p>Как только вы отправите сообщение с номером стола боту, последний мгновенно сгенерирует для вас
                    уникальный QR-код стола.</p>
                    <p>Продемонстрируйте экран вашего смартфона с данным QR-кодом вашему гостю кафе.</p>
                    <p>Распознав QR-код через смартфон, гость получит бесплатный доступ к панели отправки
                    к вам коротких уведомлений с шестью возможными просьбами:</p>
                    <div class="asks-holder">
                        <div class="asks">
                            <span style="color: #a00;">Подойдите, пожалуйста</span>
                            <span style="color: #0a0;">Уберите стол, пожалуйста</span>
                            <span style="color: #00a;">Принесите меню, пожалуйста</span>
                            <span style="color: #63044e;">Позовите мастера кальянов, пожалуйста</span>
                            <span style="color: #0aa;">Принесите счёт, оплата налом</span>
                            <span style="color: #0aa;">Принесите счёт, оплата картой</span>
                        </div>
                    </div>
                    <p>Для наглядности посмотрите <router-link to="/promo">промо ролик</router-link>.</p>
                    <v-dialog v-model="waiterPic6Dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <p>А тут посмотрите что <span v-bind="attrs" v-on="on" class="link-image-6">видит гость на экране</span></p>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2" style="word-break: break-word">
                                Что видит гость на своём экране
                            </v-card-title>
                            <v-card-text>
                                <img src="/waiter_pic_6.jpg" class="image6">
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="waiterPic6Dialog = false">Закрыть</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <p>Обращаем ваше внимание, кнопка <span style="display: inline;color: #761dc1">"Оставить чаевые"</span> на экране гостя появляется только
                        в том случае, если вы указали ссылку на агрегатора ваших чаевых в боте: бот -> меню -> места работы.</p>
                </div>
                <div class="photo">
                    <img src="/waiter_pic_4.jpg">
                </div>
            </div>
            <div class="zigzag"></div>
            <div class="row">
                <div class="num">5</div>
                <div class="text">
                    <p>Нажмите уже известный вам символ<img src="/telegram_pic_1.png" class="telegram-pic-1">, далее нажмите "Мои столы".</p>
                    <p>На экране бот покажет вам список ваших столов.</p>
                    <p>И снова у вас выбор из двух кнопок. Вы можете либо нажать на кнопку "Стол № такой-то", чтобы вызвать на экран уникальный QR-код активного стола
                    (для его повторной демонстрации вашему гостю бара, если возникнет такая необходимость), либо жмите кнопку "Удалить".</p>
                    <p>Обратите внимание, "Удалить" означает закрыть стол и разорвать связь с гостем; он уже не сможет отправлять вам уведомления.</p>
                </div>
                <div class="photo">
                    <img src="/waiter_pic_5.jpg">
                </div>
            </div>
        </div>
        <div class="instruction">
            <h1 class="sz-22">
                Сценарий обслуживания гостей с бумажными QR-кодами.
            </h1>
            <div class="row">
                <div class="text">
                    <p>Представьте как гость заходит на летню веранду или внутрь кафе, ресторана и садится за стол. В этот момент он хочет как можно скорее
                        пообщаться с официантом, чтобы получить меню и, возможно, сделать предзаказ - напиток, так как летом жарко и пить хочется всегда.</p>
                    <p>На столах в кафе, как правило, уже стоят информационные "треугольники" и прочие "листовки". В редких случаях имеются наклейки
                        на поверхности столов, призывающие гостей обращать на себя внимание с той или иной целью.</p>
                    <p>Тут же и вы расположи&#769;те ваш бумажный QR-код - приглашение, чтобы гость мог вызвать вас сразу как только сел за столик.</p>
                    <p>Эти QR - вы распечатайте самостоятельно и разложите в начале смены на всех столиках, которые обслуживаете в
                        данном заведении. Когда ваша смена закончится, соберите QR-приглашения и унесите их с собой, чтобы завтра разложить на столиках
                        вновь в этом же заведении или уже в другом, если вы работаете в нескольких кафе, ресторанах, барах одновременно.</p>
                    <p>Помните, все отпечатанные QR-приглашения универсальны и могут быть применены во всех кафе, ресторанах, барах где вы работаете,
                        так как не привязаны к этим заведениям, а привязанны к вам.</p>
                </div>
            </div>
            <div class="zigzag"></div>
            <div class="row">
                <div class="text">
                    <p>Чтобы напечатать файл с QR-приглашениями, создайте несколько столов по кнопке "Добавить стол". Укажите номер стола или его имя, никнейм и т.д.</p>
                    <p>Знайте, вы можете сгенерировать одновременно только 18 столов, если вам будет не хватать такого числа столов, то пишите нам и мы увеличим это значение.</p>
                    <p>Далее, нажмите кнопку <img src="/bot-menu-icon.png" class="telegram-pic-1"> и следующую кнопку "Генерировать PDF файл С QR-кодами столов".</p>
                    <p>Затем, в поле для сообщения укажите через запятую номера строк в списке столов или диапазон строк через тире из списка столов для генерации pdf-файла и
                        жмите символ Отправить. Бот сгенерирует файл и файл появится в виде сообщения в боте.</p>
                    <p>Теперь осталось лишь распечатать на бумаге данный файл, разрезать по линиям QR-приглашения и пользовать их в кафе, ресторанах, барах,
                        генерируя дополнительный комфорт гостям.</p>
                </div>
            </div>
        </div>
        <div class="instruction">
            <h1 class="sz-22">
                Важная кнопка <img src="/bot-menu-icon.png" class="telegram-pic-2"> в боте сервиса вызова официантов
            </h1>
            <div class="row">
                <div class="text">
                    <p>В Стюард Бот мы добавили главную кнопку <img src="/bot-menu-icon.png" class="telegram-pic-1"> с набором функций, чтобы снабдить вас дополнительными преимуществами:<p>
                    <ul style="list-style-type: none" >
                    <li><span class="blue-text">/workplace</span> - Места работы -
                        <span class="green-allow">позволяют</span> "подать" электронное меню от кафе / ресторана / бара,
                        <span class="green-allow">позволяют</span> подключить кнопку вызова мастера кальяна на экран гостя,
                        <span class="green-allow">позволяют</span> активировать кнопку-ссылку на вашу страницу перевода личных чаевых от гостя,
                        <span class="green-allow">позволяют</span> указать логин и пароль для разрешенного общественного wifi</li>
                        <li><span class="blue-text">/trash</span> - Удалённые столы - позволяет восстановить стол из десяти последних удаленных</li>
                        <li><span class="blue-text">/start</span> - Перезапустить бот - позволяет перезапустить бот, если он заглючил</li>
                        <li><span class="blue-text">/instruction</span> - Инструкция - тут лежит более подробная инструкция к управлению ботом</li>
                        <li><span class="blue-text">/write_to_dev</span> - Написать разработчику - позволяет сообщить нам что-то важное про сервис Steward.cafe</li>
                    </ul>
                    <p>Обратим ваше внимание, что указав места работ в боте, вы добавляете четыре полезные для гостей кнопки на их экраны:<p>
                        <ul style="padding-left: 40px">
                        <li>электронное меню от кафе, ресторана, бара</li>
                        <li>кнопка вызова мастера кальяна</li>
                        <li>кнопка-ссылка на вашу страницу перевода личных чаевых от гостя</li>
                        <li>кнопка WiFi, под которой гость найдёт логин и пароль разрешённой WiFi-сети заведения</li>
                    </ul>
                    <p>Включать и выключать полезные для гостей кнопки удобно виртуальными клавишами "Начать&nbsp;\&nbsp;Завершить&nbsp;смену".</p>
                </div>
            </div>
        </div>

        <div class="after-instruction-holder">
            <p class="after-instruction">
                Если у вас всё ещё остальсь вопросы и вы не смогли разобраться как пользоваться
                платформой уведомлений для официантов, то напишите нам через форму
                <router-link to="/write-to-dev">написать&nbsp;разработчику</router-link>.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Waiters",
    data: () => ({
        waiterPic6Dialog: false,
        waiterBotPicDialog: false,
        waiterPlayPicDialog: false
    })
}
</script>
<style>
.green-allow {color: #ffffff; background: #23a623;padding: 0 4px;}
.image6 {display: block;max-width: 100%; max-height:60vh;margin: 16px auto 0 auto;}
.link-image-6 {display: inline !important;color: #1976d2;text-decoration: underline}
.top-h1 {display: block; width: 100%;text-align: center;margin:0 0 50px 0;color: #aaa;}
.over-instruction p {padding:0 20px;}
.instruction {padding: 20px;box-sizing:border-box;border: 2px dotted #444;border-radius: 16px;margin: 150px 0 100px 0;}
.zigzag {position: relative;width: 280px;height: 100px;margin: auto;}
.zigzag:before {content: "";display: block;position: absolute;top: -20px;width: 100%;height: 20px;background:
        linear-gradient(63.44deg, transparent 33.333%, #ccc 33.333%, #ccc 66.667%, transparent 66.667%),
        linear-gradient(63.44deg, transparent 33.333%, #ccc 33.333%, #ccc 66.667%, transparent 66.667%);
    background-size: 40px 40px;
}
.instruction .sz-22 {text-align: center;font-size: 26px;margin: 100px 20px;color: #aaa;font-weight: 700;}
.instruction .sz-22 .telegram-pic-2 {height: 30px; vertical-align: middle;}

.instruction .row {display: flex;margin: 0 0 100px 0;}
.instruction .row:last-child {margin-bottom: 0;}

.instruction .row .num {flex: 0 0 20%;text-align: center;font-size: 250px;color: #ccc;}
.instruction .row .text {text-align: left;flex: 1 1 50%;padding: 0 10px;font-size: 16px;}
.instruction .row .photo {flex: 0 0 30%;}
.instruction .row .photo img {max-width: 100%; margin:0;border: 1px dotted #888;border-radius: 5px;}
.instruction .row .photo .lupa:hover {cursor: zoom-in}
.instruction .row .text .telegram-pic-1 {height: 20px;vertical-align: middle;}
.instruction .row .text .asks-holder {box-sizing: border-box;display: flex;justify-content: center;margin-bottom: 10px;margin-top: -5px;}
.instruction .row .text .asks {flex: 0 0 auto;flex-direction: column;}
.instruction .row .text .asks span {display: block;}
.instruction .row .text .blue-text {color: #1976d2;}

.after-instruction-holder {display: flex; justify-content: center;margin-bottom: 100px;}
.after-instruction {flex: 1 1 auto; max-width: 560px;padding: 0 20px;font-size: 16px;}


@media (max-width: 800px) {
    .instruction { /*border: 0;*/}
    .zigzag {height: 50px}
    .instruction .row {margin-bottom: 100px;}
    .instruction .row .num {flex: 0 0 100%;line-height: 100%}
    .instruction .row .text {flex: 0 0 100%;padding-bottom: 30px;}
    .instruction .row .photo {flex: 0 0 100%;}
    .instruction .row .photo img {display: block; margin: auto; max-height: 70vh; max-width: 70vw;}
    .instruction .row .photo img:nth-child(2){margin-top: 16px;}
}

</style>