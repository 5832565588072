<template>
    <div>
        <iframe width="840" height="472" src="https://www.youtube-nocookie.com/embed/I20TsqW50Ws" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
    name: "Promo"
}
</script>

<style scoped>
iframe {
    display: block;
    margin: 80px auto;
    max-width: 100%;
}
</style>