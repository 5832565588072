// import config from './config'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import store from './store'

import DefaultLayout from './layouts/DefaultLayout.vue'
import CafeLayout from "./layouts/CafeLayout.vue";

Vue.component('default-layout', DefaultLayout)
Vue.component('cafe-layout', CafeLayout)

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
}).$mount('#app')
