<template>
    <v-footer style="background: #f2f2f2">
        <v-container class="py-0" style="max-width: 1000px">
            <v-card-text>
                <v-col class="r1">
                    <v-row>
                        <v-col cols="12" :sm="g_sm">
                            <img src="@/assets/logo-3.png" class="logo">
                            <p>Вызывай официантку онлайн<br><br>Гость, используй сервис вызова официантов, когда сидишь в летнем кафе, баре, ресторане.</p>
                            <p><router-link to="/clients">Инструкция для гостя кафе</router-link></p>
                        </v-col>
                        <v-col cols="12" :sm="g_sm">
                            <h2>Связь</h2>
                            <router-link to="/write-to-dev">Написать разработчику</router-link>
                            <router-link to="/error-or-site">Сообщить об ошибке на сайте</router-link>
                        </v-col>
                        <v-col cols="12" :sm="g_sm">
                            <h2>Условия</h2>
                            <router-link to="/offer">Пользовательское соглашение</router-link>
                            <router-link to="/privacy">Политика конфиденциальности</router-link>
                        </v-col>
                        <v-col cols="12" :sm="g_sm">
                            <h2>Скачать</h2>
                            Приложение для официантов<br>
                            Опубликовано 1 августа 2022<br>
                            <a href="https://play.google.com/store/apps/details?id=steward.cafe.steward_cafe" target="_blank">
                                <img class="download-button" src="~@/assets/play-market.png"></a>
                            <div><img class="download-button wait-button" src="~@/assets/app-store.png"></div>
<!--                            <div class="nash-button wait-button">NashStore<img src="@/assets/nash-store.svg"></div>-->
                            <a href="/releases/latest.apk" download="">Скачать с сайта (Android 5+)</a>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="r2">
                    <v-row>
                        <span>{{ new Date().getFullYear() }} &copy; Steward.Cafe Автор и разработчик: <a href="http://rigellab.ru" target="_blank"> Rigel Lab</a> ::
                        Дата публикации Beta версии проекта: 1 июня 2022</span>
                    </v-row>
                </v-col>
            </v-card-text>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    computed: {
        g_sm() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 12;
                case 'sm':
                    return 6;
                case 'md':
                case 'lg':
                case 'xl':
                    return 3;

            }
            return 3;
        }
    }
};
</script>

<style scoped>
.download-button {max-width: 200px;}
.wait-button {opacity: 0.2;}
.nash-button {border-radius: 7px;border: 1px dashed #333333;display: block;width: 200px;margin: 10px 0;color: #ffffff;padding: 15px 40px;font-size: 15px;
    font-weight: 700;transition: all .4s;vertical-align: middle;font-family: Montserrat,sans-serif;background: #473bf0;text-decoration: none;letter-spacing: 1px;}
.nash-button img {width: 20px;margin-left: 5px;margin-top: -2px;vertical-align: middle;}
.nash-button:hover {/*background: #e2e2e2;cursor: pointer;text-decoration: none;*/}

.r1 {margin-top: 20px;margin-bottom: 90px;}
.r2 {border-top: 1px solid #cccccc;padding-top: 30px;}

.logo {max-width: 200px;}

h2 {margin-top: 12px;margin-bottom: 16px;font-weight: 400;font-size: 26px;}
p, h1, h2, a {display: block;color: #222222;text-decoration: none;}
a {margin: 8px auto;}
a:hover {text-decoration: underline;}
.r2 a {display: inline;margin: 0;}
</style>