<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
        <CookieNote/>
    </div>
</template>

<script>

import CookieNote from "./components/CookieNote";

export default {
    components: { CookieNote },
    computed: {
        layout() {
            return this.$route.meta.layout || 'default-layout'
        }
    }
}
</script>